import React, { useEffect, useState } from "react"
import CEOImage from "../../images/ceo-img.png"
import CSOImage from "../../images/cso-img.png"
import ExplusionZoneShapeLogo from "../../images/shapelogos/shaoe-logo-exclusion-zone.png"
import BookOfFlourishingBanner from "../../images/book-of-flourishing-banner.png"
import BookOfFlourishingBannerJpg from "../../images/book-of-flourishing-banner.jpg"
import BookOfFlourishingBannerMobile from "../../images/flourishing-banner-mobile.png"
import BookOfFlourishingBannerMobileJpg from "../../images/flourishing-banner-mobile.jpg"

import "./brand-assets.scss"
import {
  brandAssetsSubText,
  brandColor,
  exclusionZone,
  legalReminderSubText,
  logoDontsText,
  mascotSubText,
  ourBrandSubText,
  ourNameSubText,
  shapeLogos,
  theLogoSubText,
} from "./helper"

const BrandAssets = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false)
  const aliPdf = data?.contentfulBrandAssets?.aliPdf
  const johnPdf = data?.contentfulBrandAssets?.johnPdf
  const bookOfFlourishingPdf = data?.contentfulBrandAssets?.bookOfFlourishing
  const BofEmbeddingInstructionsPdf =
    data?.contentfulBrandAssets?.bofEmbeddingInstructions
  const mediaKitPdf = data?.contentfulBrandAssets?.mediaKit

  const contentfulShapeLogos = data?.contentfulBrandAssets?.shapeLogos
  // const logoVariantsZiplink = data?.contentfulBrandAssets?.logoVariants

  // const isMobile =
  //   typeof window !== `undefined` &&
  //   window.matchMedia("(max-width: 786px)").matches

  const downloadImage = (imageSrc, fileName) => {
    const link = document.createElement("a")
    link.href = imageSrc
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadShapeLogoImage = async (imageSrc, fileName) => {
    try {
      const response = await fetch(imageSrc)
      if (!response.ok) {
        throw new Error("Failed to fetch image")
      }

      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = url
      link.download = fileName
      link.style.display = "none"

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading the image:", error)
    }
  }

  const isSafari =
    typeof window !== "undefined" &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  // Function to download the image and open the PDF in a new tab
  const downloadBannerAndPdf = async () => {
    const pdfUrl = BofEmbeddingInstructionsPdf
    const bannerImage = isMobile
      ? BookOfFlourishingBannerMobileJpg
      : BookOfFlourishingBannerJpg

    // Helper function to convert image to blob and trigger download
    const downloadBlobImage = async (imageSrc, fileName) => {
      const response = await fetch(imageSrc)
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = url
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Clean up the URL object
      URL.revokeObjectURL(url)
    }

    if (isSafari) {
      // Safari needs to handle downloads differently
      await downloadBlobImage(bannerImage, "BookOfFlourishingBanner.jpg")

      setTimeout(() => {
        window.open(pdfUrl, "_blank")
      }, 500) // Small delay for Safari to process the download
    } else {
      // For other browsers, download the image and open the PDF in a new tab
      downloadImage(bannerImage, "BookOfFlourishingBanner.jpg")
      window.open(pdfUrl, "_blank")
    }
  }

  const handleDownloadAllLogos = () => {
    const logoVariantsZipLink = data?.contentfulBrandAssets?.logoVariants // URL for the zipped file

    if (!logoVariantsZipLink) {
      console.error("Zip file link is not available.")
      return
    }

    const link = document.createElement("a")
    link.href = logoVariantsZipLink
    link.download = "logo-variants.zip" // Name for the downloaded file
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    const checkIsMobile = () => {
      if (typeof window !== "undefined") {
        setIsMobile(window.matchMedia("(max-width: 786px)").matches)
      }
    }

    checkIsMobile()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIsMobile)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", checkIsMobile)
      }
    }
  }, [])

  return (
    <div className="brandAssetsPageWrapper">
      <h2 className="brandAssetsHeading">Press Kit</h2>
      <p
        className="brandAssetsSubText"
        dangerouslySetInnerHTML={{
          __html: brandAssetsSubText,
        }}
      />

      <div className="ourBrandWrapper">
        <h2 className="ourBrandHeading">Our Brand</h2>
        <p
          className="ourBrandSubText"
          dangerouslySetInnerHTML={{ __html: ourBrandSubText }}
        />
      </div>

      <div className="ourNameWrapper">
        <h2 className="ourNameHeading">Our Name</h2>

        <p
          className="ourNameSubText"
          dangerouslySetInnerHTML={{ __html: ourNameSubText }}
        />

        <h2 className="ourCoFoundersHeading">Our Co-Founders</h2>

        <div className="coFounderContentWrapper">
          <div className="CoFounder">
            <div className="coFounderImgCont">
              <img src={CEOImage} alt="ceoImage" />
            </div>

            <div className="coFounderInfoCont">
              <div className="nameAndDesignationWrapper">
                <hr className="dividerLine" />
                <div className="coFounderName">Ali Khan</div>
                <div className="coFounderDesignation">
                  Chief Executive Officer
                </div>
              </div>
              <div className="downloadWrapper">
                <a href={aliPdf} download target="_blank">
                  <div className="downloadBio">Download Bio</div>
                </a>
                <div
                  className="downloadImg"
                  onClick={() => downloadImage(CEOImage, "aliKhan.png")}
                >
                  Download Image
                </div>
              </div>
            </div>
          </div>
          <div className="CoFounder">
            <div className="coFounderImgCont">
              <img src={CSOImage} alt="CSOImage" />
            </div>
            <div className="coFounderInfoCont">
              <div className="nameAndDesignationWrapper">
                <hr className="dividerLine" />
                <div className="coFounderName">Dr. John Lang</div>
                <div className="coFounderDesignation">
                  Chief Science Officer
                </div>
              </div>
              <div className="downloadWrapper">
                <a href={johnPdf} download target="_blank">
                  <div className="downloadBio">Download Bio</div>
                </a>
                <div
                  className="downloadImg"
                  onClick={() => downloadImage(CSOImage, "johnLang.png")}
                >
                  Download Image
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="brandIdentitySection">
        <div className="brandIdentityWrapper">
          <h2 className="brandIdentityHeading">Our Brand Identity</h2>
          <div className="theLogoHeading">The Logo</div>
          <p
            className="theLogoSubText"
            dangerouslySetInnerHTML={{ __html: theLogoSubText }}
          />
          <div className="mascotHeading">The Mascot</div>
          <p
            className="mascotSubText"
            dangerouslySetInnerHTML={{ __html: mascotSubText }}
          />
          <div className="colorVariantHeading">Logo Colour Variants</div>
          <div className="logovariantCont">
            {shapeLogos?.map((logo, index) => (
              <div className="logoImgCont" key={index}>
                <img src={logo.imgSrc} alt={logo.name} />

                {isMobile && (
                  <div className="logoDownloadTextCont">
                    <div className="logoDownloadName">{logo.name}</div>
                    <div
                      className="logoDownloadBtn"
                      onClick={() => {
                        const downloadUrl =
                          contentfulShapeLogos?.[index]?.file?.url
                        if (downloadUrl) {
                          downloadShapeLogoImage(
                            `https:${downloadUrl}`,
                            logo.name
                          )
                        } else {
                          console.error("Download URL not found for this logo.")
                        }
                      }}
                    >
                      Download
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* For desktop downloads */}
          {!isMobile && (
            <div className="downloadWrapper">
              {shapeLogos?.map((logo, index) => (
                <div className="logoDownloadTextCont" key={index}>
                  <div className="logoDownloadName">{logo.name}</div>
                  <div
                    className="logoDownloadBtn"
                    onClick={() => {
                      const downloadUrl =
                        contentfulShapeLogos?.[index]?.file?.url
                      if (downloadUrl) {
                        downloadShapeLogoImage(
                          `https:${downloadUrl}`,
                          logo.name
                        )
                      } else {
                        console.error("Download URL not found for this logo.")
                      }
                    }}
                  >
                    Download
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="downloadAllBtn" onClick={handleDownloadAllLogos}>
            Download All Versions
          </div>
          <div className="exclusionZoneHeading">Logo Exclusion Zone</div>
          <p
            className="exclusionZoneSubText"
            dangerouslySetInnerHTML={{ __html: exclusionZone }}
          />

          <div className="exclusionZoneImgCont">
            <img src={ExplusionZoneShapeLogo} alt="exclusionZoneShapeLogo" />
          </div>

          <div className="logoDontsHeading">Logo Don’ts</div>

          <div className="logoDontsCont">
            <div className="logoDontsTextCont">
              {logoDontsText?.slice(0, 3)?.map((logoDonts, index) => (
                <div className="logoDontText" key={index}>
                  {logoDonts.content}
                </div>
              ))}
            </div>
            <div className="logoDontsTextCont">
              {logoDontsText?.slice(-3)?.map((logoDonts, index) => (
                <div className="logoDontText" key={index}>
                  {logoDonts.content}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="brandColorsWrapper">
          <div className="brandColorsHeadingCont">
            <div className="brandColorsHeading">The Brand Colours</div>
          </div>

          <div className="brandColorImgWrapper">
            {brandColor?.map((color, index) => (
              <div className="brandColorImgCont" key={index}>
                <img src={color.imgSrc} alt={color.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bookOfFlourishingWrapper">
        {isMobile ? (
          <div className="bookOfFlourishingImgCont">
            <img
              src={BookOfFlourishingBannerMobile}
              alt="book of flourishing banner"
            />
          </div>
        ) : (
          <div className="bookOfFlourishingImgCont">
            <img
              src={BookOfFlourishingBanner}
              alt="book of flourishing banner"
            />
          </div>
        )}

        <div className="downloadFlourishingCont">
          <a href={bookOfFlourishingPdf} download target="_blank">
            <div className="downloadText">Download Book of Flourishing</div>
          </a>
          <a href={BofEmbeddingInstructionsPdf} download target="_blank">
            <div className="downloadText" onClick={downloadBannerAndPdf}>
              Download Book of Flourishing Banner
            </div>
          </a>
        </div>
      </div>

      <div className="legalReminderWrapper">
        <h2 className="legalReminderHeading">Friendly Legal Reminder</h2>
        <p className="legalReminderSubText">{legalReminderSubText}</p>
        <a
          href={mediaKitPdf}
          onClick={async (e) => {
            e.preventDefault()

            // Fetch the PDF
            const response = await fetch(mediaKitPdf)
            const blob = await response.blob()

            // Create a temporary link to force the download
            const link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.setAttribute("download", "BrandAssets.pdf") // Specify the file name
            document.body.appendChild(link)
            link.click()

            // Clean up the DOM
            link.remove()
          }}
        >
          <div className="downloadPressKit">Download Press Kit</div>
        </a>
      </div>

      <div className="getInTouchWrapper">
        <a href="/contact">
          <div className="getInTouchText">Got questions? Get in touch.</div>
        </a>
      </div>
    </div>
  )
}

export default BrandAssets
